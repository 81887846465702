import React, { useState, useEffect } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Seo from "../components/Seo";
import Layout from "../layouts/LayoutEmpty";
import Container from "../components/common/Container";
import ProgressView from "../components/request-upgrade/Progress";
import ErrorContainer from "../containers/request-upgrade/ErrorContainer";
import CheckController from "../controllers/request-upgrade/checkController";
import AnalyticsController from "../controllers/request-upgrade/analyticsController";

const RequestUpgrade = () => {
  const [getErrorState, setErrorState] = useState(false);

  useEffect(() => {
    const checkController = new CheckController();
    const analyticsController = new AnalyticsController();

    const reject = () => {
      analyticsController.send("reject", "upgrade").finally(() => {
        setErrorState(true);
      });
    };

    if (!checkController.checkParams()) {
      checkController.requestUpgrade().then(
        () => {
          analyticsController.send("done", "upgrade").finally(() => {
            checkController.onSuccess();
          });
        },
        (error) => {
          reject();
        }
      );
    } else {
      reject();
    }
  }, []);

  return (
    <Layout isShowHelp>
      <Seo
        title="Amazon Product Research Tool & Niche Finder | AMZScout"
        description="Make Amazon product research quick and easy with accurate research software from AMZScout. Analyze niches and find the right products to help you make money."
        page=""
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <Container>
        {getErrorState ? (
          <ErrorContainer />
        ) : (
          <ProgressView onError={setErrorState} />
        )}
      </Container>
    </Layout>
  );
};

export default RequestUpgrade;
