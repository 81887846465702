import React from "react";
import SVGMainLogo from "../../../assets/images/vector/components/common/logo_amzscout_black.svg";
import GIFProgress from "../../../assets/images/gif/progress.gif";
import "./index.scss";

const ProgressView = () => {
  return (
    <div className="ProgressView">
      <img className="ProgressView__logo" src={SVGMainLogo} alt="icon" />
      <img className="ProgressView__loader" src={GIFProgress} alt="loader" />
    </div>
  );
};

export default ProgressView;
