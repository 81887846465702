import React from "react";
import SVGBadSmile from "../../../assets/images/vector/pages/request-upgrade/Error/bad-smile.svg";
import "./index.scss";
import PropTypes from "prop-types";

const ErrorView = ({ title, description, cta, onBack }) => {
  return (
    <div className="ErrorView">
      <img className="ErrorView__logo" src={SVGBadSmile} alt="icon" />
      <p className="ErrorView__title">{title}</p>
      {description && (
        <div className="ErrorView__description">
          {description[0]}{" "}
          <a href="mailto:support@amzscout.net">{description[1]}</a>{" "}
          {description[2]}
        </div>
      )}
      <button className="ErrorView__btn" onClick={onBack}>
        {cta}
      </button>
    </div>
  );
};

ErrorView.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.array,
  cta: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default ErrorView;
