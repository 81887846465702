class RequestInfoModel {
  constructor(newTariffPlan, licenceId, token) {
    this.newTariffPlan = newTariffPlan;
    this.licenceId = licenceId;
    this.requestHeaders = {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      "X-Token": token,
    };
  }
  request() {
    return fetch(
      process.env.SUBSCRIPTIONS +
        `/change/info?newTariffPlan=${this.newTariffPlan}&licenceId=${this.licenceId}`,
      {
        headers: this.requestHeaders,
        method: "GET",
      }
    );
  }
}

export default RequestInfoModel;
