import React, { useEffect, useState } from "react";
import ErrorView from "../../components/request-upgrade/Error";

const ErrorContainer = () => {
  const [i18n, setI18n] = useState({ t: () => "" });

  useEffect(() => {
    import("../../i18n/request-upgrade").then((data) => {
      setI18n(data.default);
    });
  }, []);

  const handleGoBack = () => {
    if (typeof window !== "undefined") {
      const params = new URL(window.location.href).searchParams;
      const url = params.get("url");
      if (url) {
        return window.location.replace(
          `${url}${url.includes("?") ? "&" : "?"}failed=true`
        );
      } else {
        return window.history.back();
      }
    }
  };

  return (
    <ErrorView
      title={i18n.t("title")}
      description={i18n.t("description")}
      cta={i18n.t("cta")}
      onBack={handleGoBack}
    />
  );
};

export default ErrorContainer;
