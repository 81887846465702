import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";

class AnalyticsController {
  constructor() {
    this.params = new URL(window.location.href).searchParams;
    this.software = this.params.get("software");
    this.category = `${this.software}|Upgrade`;
    this.currentTariff = this.params.get("t")
      ? atob(this.params.get("t"))
      : null;
    this.currentPeriod = this.params.get("p")
      ? atob(this.params.get("p"))
      : null;
    this.newTariffPlan = this.params.get("n")
      ? atob(this.params.get("n"))
      : null;
    this.label = this.createUpgradeLabel();
  }

  createBasicLabel() {
    return `${this.currentTariff}_${this.currentPeriod}|${this.newTariffPlan}`;
  }

  createUpgradeLabel() {
    return `upgrade_request|${this.createBasicLabel()}`;
  }

  send(action, type) {
    this.action = `${action}-${type}`;

    if (this.label) {
      trackGa(this.action, {
        category: this.category,
        label: this.label,
        transport_type: "beacon",
      });
      return track(this.category, this.action, this.label, null, this.software);
    }
  }
}

export default AnalyticsController;
