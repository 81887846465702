import RequestInfoModel from "../../models/request-upgrade/requestInfoModel";

class CheckController {
  constructor() {
    this.params = new URL(window.location.href).searchParams;
    this.software = this.params.get("software");
    this.url = this.params.get("url");
    this.tariff = this.params.get("t") ? atob(this.params.get("t")) : null;
    this.period = this.params.get("p") ? atob(this.params.get("p")) : null;
    this.licenseId = this.params.get("l") ? atob(this.params.get("l")) : null;
    this.hash = this.params.get("h") ? atob(this.params.get("h")) : null;
    this.newTariffPlan = this.params.get("n")
      ? atob(this.params.get("n"))
      : null;
  }

  requestUpgrade() {
    this.requestInfoModel = new RequestInfoModel(
      this.newTariffPlan,
      this.licenseId,
      this.hash
    );
    return this.requestInfoModel
      .request()
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        console.log(data);
        localStorage.setItem(
          "upgrade",
          JSON.stringify({
            ...data,
            newTariffPlan: this.newTariffPlan,
            licenceId: this.licenseId,
            token: this.hash,
            period: this.period,
            software: this.software,
            tariff: this.tariff,
            returnUrl: this.url || null,
          })
        );
        return true;
      });
  }

  checkParams() {
    return (
      !this.tariff ||
      !this.period ||
      !this.licenseId ||
      !this.hash ||
      !this.newTariffPlan
    );
  }

  onSuccess() {
    return (window.parent.location.href = `/upgrade`);
  }
}

export default CheckController;
